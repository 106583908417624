
import { defineComponent, PropType } from "vue";
import {
  ScheduleError,
  ErrorLevel,
  isMilestoneEvent,
  TANDEM_DATE_SHORT,
} from "@/core";

export default defineComponent({
  props: {
    errors: {
      type: Array as PropType<ScheduleError[]>,
      default: () => [],
    },
  },
  emits: ["show-details"],
  methods: {
    handleClick(error: ScheduleError) {
      this.$emit("show-details", error);
    },
    errorClassName(error: ScheduleError) {
      return error.level === ErrorLevel.Warning ? "warning" : "critical";
    },
    errorDateRange(error: ScheduleError): string | null {
      if (!error.instance) {
        return null;
      }
      const publishDate =
        error.instance.publishDate.toFormat(TANDEM_DATE_SHORT);
      const dueDate = error.instance.dueDate.toFormat(TANDEM_DATE_SHORT);
      return isMilestoneEvent(error.instance.eventType)
        ? publishDate
        : `${publishDate}–${dueDate}`;
    },
  },
});
